import { FC, FormEventHandler, useContext, useState } from 'react';
import ButtonIcon from '../../components/ButtonIcon/ButtonIcon';
import styles from './CommentForm.module.scss';
import { useTranslation } from 'react-i18next';
import MentionsEditor from '../../components/MentionsEditor/MentionsEditor';

import api from '../../util/api';
import { usePosts } from '../../util/APIDjango';
import { MainContext } from '../../context/MainContext';
import { Send } from '../../components/Icons/Send';
import { editorStateToString } from '../../components/MentionsEditor/MentionsEditorChat';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getRoot } from 'lexical';

const maxLength = 1000;

const CommentForm: FC<{ postId: string }> = ({ postId }) => {
  const { t } = useTranslation();
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [currentLength, setCurrentLength] = useState(0);

  const [post, setPost] = usePosts();
  const { fetchCommentsForPost } = useContext(MainContext);
  const [editor] = useLexicalComposerContext();
  const handleSubmit: FormEventHandler = e => {
    e.preventDefault();
    if (currentLength > maxLength) {
      return;
    }
    setSubmitting(true);
    const content = editorStateToString(editor);
    // dispatch(commentCreateDispatch(postId));
    postSubmitDispatch(postId);
    api
      .createComment({ postId, content })
      .then((comment: any) => {
        // dispatch(commentCreateFulfill(postId, comment));
        postSubmitFulfill(postId);
        fetchCommentsForPost(postId);
        setSubmitting(false);
        editor.update(() => {
          $getRoot().clear();
        });
      })
      .catch((err: any) => {
        // dispatch(commentCreateReject(err));
        postSubmitError(err);
        setSubmitting(false);
      });
  };

  const postSubmitDispatch = (postID: string) => {
    setPost({
      ...post,
      isPosting: true,
      isPosted: false,
      error: null,
    });
  };

  const postSubmitFulfill = (postID: string) => {
    setPost({
      ...post,
      isPosting: false,
      isPosted: true,
      error: null,
    });
  };

  const postSubmitError = (error: any) => {
    setPost({
      ...post,
      isPosting: false,
      isPosted: false,
      error: error,
    });
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <div className={styles.container}>
        <MentionsEditor
          id="comment-input"
          placeholder={t('addComment.label')}
          disabled={isSubmitting}
          maxLength={maxLength}
          currentLength={currentLength}
          setCurrentLength={(newLength: number) => setCurrentLength(newLength)}
        />
        <ButtonIcon className={styles.submit} title="Comment" type="submit" icon={<Send />} />
      </div>
    </form>
  );
};

export default CommentForm;
